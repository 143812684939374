import * as MdIcons from "react-icons/md";
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function TrainingsPage() {
    const [deleteId, setDeleteId] = useState("");
    const [trainings, setTrainings] = useState([]);
    const [viewTraining, setViewTraining] = useState("");
    const [showView, setShowView] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const { setValue } = useForm();

    const handleCloseDeleteModal = () => setShowDelete(false);
    const handleShowDeleteModal = () => setShowDelete(true);
    const handleCloseViewModal = () => setShowView(false);
    const handleShowViewModal = () => setShowView(true);

    const handleClickDeleteModal = (id) => {
        setDeleteId(id);
        handleShowDeleteModal();
    }

    const handleClickViewModal = async (id) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/trainings/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const trainingView = data;
        setViewTraining(trainingView);
        setValue('name', trainingView.name);
        setValue('authors', trainingView.authors);
        setValue('description', trainingView.description);
        setValue('format', trainingView.format);
        setValue('url', trainingView.url);
        setValue('categories', trainingView.categories);
        handleShowViewModal();
    }

    const handleClickDownloadFile = async (id, name) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");

        Axios({
            url: `${apiUrl}/api/trainings/${id}/download`,
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: "blob" // important
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                name
            );
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            document.getElementById("splash").style.display = "none";
        }).catch(function (error) {
            console.log(error);
            document.getElementById("splash").style.display = "none";
        });
    }

    const handleDeleteClick = () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.delete(`${apiUrl}/api/trainings/${deleteId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('Training successfully deleted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchTrainings();
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setDeleteId("");
            handleCloseDeleteModal();
        });
    }

    const fetchTrainings = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/trainings`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const trainings = data;
        setTrainings(trainings);
    };

    useEffect(() => {
        fetchTrainings();
    }, []);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <Modal size="xl" show={showView} onHide={handleCloseViewModal}>
                <Modal.Header closeButton>
                    <Modal.Title>View training</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate id="viewForm">
                        <Form.Group className="mb-4" controlId="viewTraining.name">
                            <Form.Label>Name</Form.Label>
                            <input
                                type="text"
                                className={`form-control`}
                                placeholder="name"
                                value={viewTraining?.name}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="viewTraining.authors">
                            <Form.Label>Name of the authors</Form.Label>
                            <input
                                type="text"
                                className={`form-control`}
                                value={viewTraining?.authors}
                                placeholder="authors"
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="viewTraining.description">
                            <Form.Label>Description</Form.Label>
                            <textarea
                                className={`form-control`}
                                value={viewTraining?.description}
                                placeholder="description"
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="viewTraining.format">
                            <Form.Label>Format</Form.Label>
                            <input
                                type="text"
                                className={`form-control`}
                                value={viewTraining?.format}
                                placeholder="format"
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="viewTraining.url">
                            <Form.Label>Link (Url)</Form.Label>
                            <input
                                type="text"
                                className={`form-control`}
                                value={viewTraining?.url}
                                placeholder="url"
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="viewTraining.resourceTypes">
                            <Form.Label>Type of resource</Form.Label>
                            {
                                viewTraining?.categories?.map((category, index) => {
                                    return (
                                        <div key={category} className="">
                                            <span>- {category}</span>
                                        </div>
                                    );
                                })
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDelete} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete training</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this training?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="content d-flex flex-column flex-column-fluid">
                <div className=' container-fluid '>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-header">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="d-flex flex-column text-dark fw-bold my-0 fs-1">
                                            Trainings
                                        </h1>
                                    </div>
                                </div>
                                <div className="card-body pt-1">
                                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="table-responsive">
                                            <Table className="table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                                                <thead className="fw-bold text-muted">
                                                    <tr>
                                                        <th className="sorting">Name</th>
                                                        <th className="sorting">Format</th>
                                                        <th className="sorting">File / Url</th>
                                                        <th className="sorting">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        trainings.map((item, index) => {
                                                            return (
                                                                <tr key={item.id} className={index % 2 === 0 ? 'odd' : 'even'}>
                                                                    <td className="min-w-150px">
                                                                        <div className="mb-2">{item.name}</div>
                                                                    </td>
                                                                    <td className="min-w-175px">
                                                                        <div className="mb-2 fw-bold">{item.format}</div>
                                                                    </td>
                                                                    <td className="min-w-175px">
                                                                        {
                                                                            item.url && (
                                                                                <a href={item.url} target="_blank" rel="noreferrer" className="text-primary">
                                                                                    <MdIcons.MdLink />
                                                                                </a>
                                                                            )
                                                                        }
                                                                        {
                                                                            item.file && (
                                                                                <Button variant="secondary" className="btn-icon btn-sm w-25px h-25px me-2" onClick={() => handleClickDownloadFile(item.id, item.file)}>
                                                                                    <MdIcons.MdCloudDownload />
                                                                                </Button>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td className="align-items-center">
                                                                        <Button variant="secondary" className="btn-icon btn-sm w-25px h-25px me-2" onClick={() => handleClickViewModal(item.id)}>
                                                                            <MdIcons.MdRemoveRedEye />
                                                                        </Button>
                                                                        <Button variant="danger" className="btn-icon btn-sm w-25px h-25px" onClick={() => handleClickDeleteModal(item.id)}>
                                                                            <MdIcons.MdDeleteForever style={{ color: '#fff' }} />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}